import { ReactElement, ReactNode, Fragment, useCallback, useEffect, useMemo, memo } from 'react';
import styled from 'styled-components';

import { Box, Grid, GridSize } from '@breathelife/mui';
import {
  isRenderingAgreeField,
  isRenderingButtonField,
  isRenderingCheckboxGroupField,
  isRenderingOptionField,
  isRenderingPlaceholderField,
  isRenderingRadioField,
  isRenderingTextField,
  isTextFieldType,
  OnAnswerChange,
  OptionWidth,
  RenderingAutocompleteField,
  RenderingField,
  RenderingNumberField,
  RenderingOptionField,
  RepeatedIndices,
} from '@breathelife/questionnaire-engine';
import { FieldTypes, FieldSizes, Language, IconName } from '@breathelife/types';
import {
  ActionButton,
  AddressAutocompleteInput,
  Icon,
  DatePicker,
  InfoSupplement,
  InputVariant,
  MoneyInput,
  NumberInput,
  PhoneInput as PhoneField,
  SelectMUI as SelectField,
  TextInput as TextField,
  SearchableSelect as SearchableSelectField,
  YearMonthField,
} from '@breathelife/ui-components';

import { getFieldSize, getVisibleOptions, shouldForceNewLine } from '../../Helpers/field';
import { getInputVariant } from '../../Helpers/inputVariant';
import { translate } from '../../Localization/Localizer';
import { StyleVariant } from '../FieldGenerator/FieldGenerator';
import { Agree as AgreeField } from './Agree/Agree';
import { CheckboxAgree } from './Agree/CheckboxAgree';
import { CheckboxField } from './Checkbox/Checkbox';
import { CheckboxGroup } from './Checkbox/CheckboxGroup';
import { RadioGroup as RadioGroupField } from './RadioGroup/RadioGroup';
import { SpacerField } from './SpacerField';

const INFO_ICON_BUTTON_SIZE = 1;

const CenteredAlignedGrid = styled(Grid)`
  align-self: center;
`;

const FieldGrid = styled(Grid)`
  * :not(.MuiFormControlLabel-label) {
    min-width: fit-content;
  }
`;

type FieldProps = {
  field: RenderingField;
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any) => void;
  styleVariant: StyleVariant;
  repeatedIndices?: RepeatedIndices;
  locale?: Language;
  iconMap?: Record<string, string>;
  onInfoIconClick?: () => void;
  onError?: (fieldId: string, error?: string) => void;
};

export function InnerField(props: FieldProps): ReactElement | null {
  const {
    field,
    onAnswerChange,
    onAnswerComplete: onAnswerCompleteCallback,
    onError,
    styleVariant,
    repeatedIndices,
    locale,
    iconMap,
    onInfoIconClick,
  } = props;

  const onAnswerComplete = useCallback(
    (fieldId: string, answer: any, previousAnswer: any) => {
      onAnswerCompleteCallback(fieldId, answer, previousAnswer);
    },
    [onAnswerCompleteCallback],
  );

  const onAnswerChangeForFieldProps = useCallback(
    (answer: any, triggerStepNavigation = field.triggerStepNavigation) => {
      onAnswerChange(field.nodeId, answer, field.effects, repeatedIndices, triggerStepNavigation);
    },
    [onAnswerChange, repeatedIndices, field.effects, field.nodeId, field.triggerStepNavigation],
  );

  useEffect(() => {
    // Ignore the validity of hidden fields
    if (!field.visible) return;

    if (!field.valid) {
      onError?.(field.id, field.validationError?.message);
    }
  }, [field.id, field.valid, field.validationError?.message, field.visible, onError]);

  if (!field.visible) return null;

  const inputVariant: InputVariant = getInputVariant(styleVariant);
  const forceNewLine = shouldForceNewLine(field);
  const options = isRenderingOptionField(field) ? getVisibleOptions(field) : [];

  const placeholder = getPlaceholderForField(field);
  const disabled = field.readOnly || field.disabled;

  const fieldProps = {
    id: field.id,
    name: field.id,
    ['data-testid']: field.nodeId,
    label: field.label || '',
    onAnswerChange: onAnswerChangeForFieldProps,
    // TODO refactor `onAnswerComplete` to abstract the usage of `field.id`, similar to `onAnswerChange`
    onAnswerComplete,
    disabled,
    value: field.value,
    validationError: field.validationError,
    title: field.title || '',
    subtitle: field.text,
    required: !field.optional,
    locale,
    optionalText: translate('validation.optional', { locale }),
  };

  const fieldSize = getFieldSize(field);
  const isFullSize = fieldSize === FieldSizes.full;
  const imageSrc = field.info?.image && iconMap?.[field.info.image.name];
  const infoSupplementImage = imageSrc
    ? {
        src: imageSrc,
        alt: field.info?.image?.alt,
      }
    : undefined;

  const defaultContainerProps: FieldContainerProps = {
    forceNewLine,
    fieldSize,
    field,
    styleVariant,
    infoSupplementImage,
    onInfoIconClick,
  };

  switch (field.type) {
    case FieldTypes.input:
      const symbol = isRenderingTextField(field) ? field.symbol : undefined;
      return (
        <FieldContainer {...defaultContainerProps}>
          <TextField
            {...fieldProps}
            placeholder={placeholder}
            symbol={symbol}
            inputVariant={inputVariant}
            allowTextWrap={isFullSize}
          />
        </FieldContainer>
      );
    case FieldTypes.textarea:
      return (
        <FieldContainer {...defaultContainerProps}>
          <TextField
            {...fieldProps}
            placeholder={placeholder}
            multiline={true}
            rows={3}
            rowsMax={9}
            inputVariant={inputVariant}
            allowTextWrap={isFullSize}
          />
        </FieldContainer>
      );
    case FieldTypes.number:
      return (
        <FieldContainer {...defaultContainerProps}>
          <NumberInput
            {...fieldProps}
            placeholder={placeholder}
            inputVariant={inputVariant}
            numericalDataType={(field as RenderingNumberField).numericalDataType}
            allowTextWrap={isFullSize}
          />
        </FieldContainer>
      );
    case FieldTypes.money:
      return (
        <FieldContainer {...defaultContainerProps}>
          <MoneyInput {...fieldProps} placeholder={placeholder} allowTextWrap={isFullSize} />
        </FieldContainer>
      );
    case FieldTypes.phone:
      return (
        <FieldContainer {...defaultContainerProps}>
          <PhoneField
            {...fieldProps}
            saveOnBlur={true}
            placeholder={placeholder}
            inputVariant={inputVariant}
            allowTextWrap={isFullSize}
          />
        </FieldContainer>
      );
    case FieldTypes.radio:
      let radioOptionWidth: OptionWidth = 'full';

      if (isRenderingRadioField(field) && field.optionSize) {
        radioOptionWidth = field.optionSize;
      }

      return (
        <FieldContainer {...defaultContainerProps} withoutInfoSupplement>
          <RadioGroupField
            {...fieldProps}
            optionWidth={radioOptionWidth}
            iconMap={iconMap}
            options={options}
            displayOnlySelected={(field as RenderingOptionField).displayOnlySelected}
            styleVariant={styleVariant}
            onInfoIconClick={props.onInfoIconClick}
            renderingInfoSupplement={field.info}
          />
        </FieldContainer>
      );
    case FieldTypes.checkbox:
      return (
        <FieldContainer {...defaultContainerProps}>
          <CheckboxField
            {...fieldProps}
            showError={!!fieldProps.validationError}
            label={fieldProps.label}
            styleVariant={styleVariant}
          />
        </FieldContainer>
      );
    case FieldTypes.checkboxGroup:
      let checkboxOptionWidth: OptionWidth = 'full';
      if (isRenderingCheckboxGroupField(field) && field.optionSize) {
        checkboxOptionWidth = field.optionSize;
      }
      return (
        <FieldContainer {...defaultContainerProps}>
          <CheckboxGroup
            {...fieldProps}
            label={fieldProps.label}
            iconMap={iconMap}
            options={options}
            optionWidth={checkboxOptionWidth}
            styleVariant={styleVariant}
            onInfoIconClick={props.onInfoIconClick}
          />
        </FieldContainer>
      );
    case FieldTypes.dropdown:
      if ((field as RenderingOptionField).searchable) {
        return (
          <FieldContainer {...defaultContainerProps}>
            <SearchableSelectField
              {...fieldProps}
              placeholder={placeholder ?? translate('select', { locale })}
              options={options}
              inputVariant={inputVariant}
              label={field.title}
              required={!field.optional}
            />
          </FieldContainer>
        );
      }
      return (
        <FieldContainer {...defaultContainerProps}>
          <SelectField
            {...fieldProps}
            placeholder={placeholder ?? translate('select', { locale })}
            options={options}
            inputVariant={inputVariant}
          />
        </FieldContainer>
      );

    case FieldTypes.date:
      return (
        <FieldContainer {...defaultContainerProps}>
          <DatePicker {...fieldProps} inputVariant={inputVariant} validationData={field?.validationData} />
        </FieldContainer>
      );

    case FieldTypes.yearMonth:
      return (
        <FieldContainer {...defaultContainerProps}>
          <YearMonthField {...fieldProps} inputVariant={inputVariant} />
        </FieldContainer>
      );

    case FieldTypes.agree:
      if (styleVariant === StyleVariant.consumer) {
        if (isRenderingAgreeField(field)) {
          return (
            <FieldContainer {...defaultContainerProps}>
              <CheckboxAgree
                {...fieldProps}
                title={field.title || ''}
                showError={!!fieldProps.validationError}
                styleVariant={styleVariant}
                consentText={field.modalText || ''}
              />
            </FieldContainer>
          );
        }
      } else if (styleVariant === StyleVariant.pro) {
        if (isRenderingAgreeField(field)) {
          return (
            <FieldContainer {...defaultContainerProps}>
              <AgreeField
                {...fieldProps}
                text={field.text}
                title={field.title || ''}
                confirmedLabel={field.confirmedLabel}
                modalHeader={field.modalHeader}
                modalText={field.modalText}
              />
            </FieldContainer>
          );
        }
      }
      break;

    case FieldTypes.button:
      if (isRenderingButtonField(field)) {
        return (
          <FieldContainer {...defaultContainerProps}>
            <ActionButton
              promptText={field.label || ''}
              linkText={field.buttonText || ''}
              onAnswerChange={fieldProps.onAnswerChange}
              resetOnMount={styleVariant === StyleVariant.consumer}
              icon={field.iconName ? <Icon name={IconName[field.iconName as IconName]} size='20px' /> : undefined}
            />
          </FieldContainer>
        );
      }
      break;

    case FieldTypes.autocomplete:
      const { countryCode, nodeIdsToUpdate } = field as RenderingAutocompleteField;

      // onAnswerComplete is not defined on AddressAutoCompleteInput
      const { onAnswerComplete: __onAnswerComplete, ...addressAutoCompleteInputFieldProps } = fieldProps;

      return (
        <FieldContainer {...defaultContainerProps}>
          <AddressAutocompleteInput
            {...addressAutoCompleteInputFieldProps}
            countryCode={countryCode}
            onAutocompleteAnswerChange={(answerValue: unknown, nodeId: string) => {
              onAnswerChange(nodeId, answerValue, field.effects, repeatedIndices, false);
            }}
            nodeIdsToUpdate={nodeIdsToUpdate}
            inputVariant={inputVariant}
            placeholder={placeholder}
            variant='outlined'
          />
        </FieldContainer>
      );

    case FieldTypes.information:
    case FieldTypes.currencyCard:
      return (
        <FieldContainer {...defaultContainerProps}>
          <Fragment />
        </FieldContainer>
      );

    // This is handled by the dynamic PDF package, and we don't render this field type with the field-generator
    case FieldTypes.signature:
      return null;

    default:
      throw new Error('Please specify the type of field in FieldGenerator');
  }
  return <FieldContainer {...defaultContainerProps} />;
}

function getPlaceholderForField(field: RenderingField): string | undefined {
  return isRenderingPlaceholderField(field) ? field.placeholder : undefined;
}

export const Field = memo(InnerField);

interface FieldContainerProps {
  forceNewLine: boolean;
  fieldSize: FieldSizes;
  field: RenderingField;
  styleVariant: StyleVariant;
  infoSupplementImage?: { src: string; alt?: string };
  onInfoIconClick?: () => void;
  withoutInfoSupplement?: boolean;
  children?: ReactNode;
}

function FieldContainer(props: FieldContainerProps): ReactElement {
  const {
    forceNewLine,
    field,
    children,
    fieldSize,
    styleVariant,
    onInfoIconClick,
    infoSupplementImage,
    withoutInfoSupplement = false,
  } = props;

  const gridSize = useMemo(() => {
    if (withoutInfoSupplement) {
      return 12;
    }
    return (field.info ? fieldSize - INFO_ICON_BUTTON_SIZE : fieldSize) as GridSize;
  }, [withoutInfoSupplement, field.info, fieldSize]);

  return (
    <Fragment>
      {forceNewLine && <SpacerField styleVariant={styleVariant} />}

      <FieldGrid item xs={gridSize} data-testid={field.id}>
        {children}
      </FieldGrid>
      {withoutInfoSupplement === false && field.info && (
        <CenteredAlignedGrid item xs={INFO_ICON_BUTTON_SIZE}>
          <Box mt={getTopMarginForInfoItem(styleVariant, field.type, !!field.label, !!field.text)}>
            <InfoSupplement
              title={field.info.title}
              text={field.info.text}
              image={infoSupplementImage}
              modalOptions={field.info.modalOptions}
              onClick={onInfoIconClick}
            />
          </Box>
        </CenteredAlignedGrid>
      )}
    </Fragment>
  );
}

function getTopMarginForInfoItem(
  styleVariant: StyleVariant,
  fieldType: FieldTypes,
  hasLabel: boolean,
  hasText: boolean,
): number {
  // The size of each field is consistent in the pro/lcp platform, meaning we can use the same margin value for all fields
  // But on the consumer platform, radios and checkboxes' height is much smaller than the height of the inputs, dropdowns etc.
  // TODO: Redesign this margin logic to not require these hardcoded values.
  if (styleVariant === StyleVariant.pro) return 4.2;
  if (fieldType === FieldTypes.checkbox) return 2;
  if (isTextFieldType(fieldType)) return 3;
  if (hasText) return 3;
  if (hasLabel) return 5;
  return 2;
}
