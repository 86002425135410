import { memo, ReactElement, useCallback, useMemo } from 'react';

import {
  getRepeatedQuestionIndex,
  OnAnswerChange,
  RenderingField,
  RenderingRepeatedQuestion,
  RepeatedIndices,
} from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { StyleVariant } from '../../../FieldGenerator/FieldGenerator';
import { QuestionContent } from '../QuestionContent';
import { Container } from '../Styles';
import { RepeatableQuestionContent } from './RepeatableQuestionContent';

type RepeatableQuestionProps = {
  question: RenderingRepeatedQuestion;
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any) => void;
  styleVariant: StyleVariant;
  repeatedIndices: RepeatedIndices;
  locale?: Language;
  iconMap?: Record<string, string>;
};

export const RepeatableQuestion = memo(InnerRepeatableQuestion);

export function InnerRepeatableQuestion(props: RepeatableQuestionProps): ReactElement {
  const { question, onAnswerChange, onAnswerComplete, styleVariant, locale, iconMap, repeatedIndices } = props;
  const repeatedQuestionIndex: number = getRepeatedQuestionIndex(question);

  const addRepeatedQuestion = useCallback(() => {
    onAnswerChange(question.nodeId, {}, question.effects, {
      ...repeatedIndices,
      [question.nodeId]: repeatedQuestionIndex + 1,
    });
  }, [onAnswerChange, question.effects, question.nodeId, repeatedIndices, repeatedQuestionIndex]);

  const deleteRepeatedQuestion = useCallback(() => {
    onAnswerChange(question.nodeId, undefined, question.effects, {
      ...repeatedIndices,
      [question.nodeId]: repeatedQuestionIndex,
    });
  }, [onAnswerChange, question.effects, question.nodeId, repeatedIndices, repeatedQuestionIndex]);

  const renderContent = useMemo(() => {
    return renderRepeatableQuestionContent(onAnswerChange, styleVariant, locale, iconMap);
  }, [iconMap, locale, onAnswerChange, styleVariant]);

  return (
    <Container
      styleVariant={styleVariant}
      key={question.surrogateId ?? question.id}
      data-testid={'repeatable_question'}
    >
      <RepeatableQuestionContent
        question={question}
        repeatedIndices={repeatedIndices}
        onQuestionAdd={addRepeatedQuestion}
        onQuestionDelete={deleteRepeatedQuestion}
        onAnswerComplete={onAnswerComplete}
        renderContent={renderContent}
        styleVariant={styleVariant}
      />
    </Container>
  );
}

function renderRepeatableQuestionContent(
  onAnswerChange: OnAnswerChange,
  styleVariant: StyleVariant,
  locale?: Language,
  iconMap?: Record<string, string>,
) {
  return (
    fields: RenderingField[],
    onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any, repeatedQuestionIndex?: number) => void,
    repeatedIndices: RepeatedIndices,
  ) => (
    <QuestionContent
      fields={fields}
      onAnswerChange={onAnswerChange}
      onAnswerComplete={onAnswerComplete}
      styleVariant={styleVariant}
      repeatedIndices={repeatedIndices}
      locale={locale}
      iconMap={iconMap}
    />
  );
}
